<template>
  <div :class="{ container: true, pb: canSubmit }">
    <nav-bar :title="`${menuName}报告`"></nav-bar>
    <template v-if="isMerge">
      <md-field v-for="(answer, index) in answers" :key="index">
        <h3 class="w100 center margin-bottom">{{ answer.name || '' }}</h3>
        <md-bill v-for="item in answer.body" :key="item.id" water-mark="火专家  火专家  火专家  火专家" class="margin-bottom">
          <div class="header-slot" slot="header">
            <p class="desc">{{ `${item.number}.${item.name}` }}</p>
          </div>

          <md-detail-item title="结果">
            <md-tag size="large" shape="fillet" type="fill" :fill-color="item.answer | answerColor">
              {{ item.answer | answerText }}
            </md-tag>
          </md-detail-item>
          <md-detail-item title="备注">{{ item.remark }} </md-detail-item>

          <div class="footer-slot center" slot="footer">
            <img v-for="img in item.pic" :key="img" :src="img | imgSrc" />
          </div>
        </md-bill>
      </md-field>
    </template>
    <template v-else>
      <md-field>
        <md-bill v-for="item in answers" :key="item.id" water-mark="火专家  火专家  火专家  火专家" class="margin-bottom">
          <div class="header-slot" slot="header">
            <p class="desc">{{ `${item.number}.${item.name}` }}</p>
          </div>

          <md-detail-item title="结果">
            <md-tag size="large" shape="fillet" type="fill" :fill-color="item.answer | answerColor">
              {{ item.answer | answerText }}
            </md-tag>
          </md-detail-item>
          <md-detail-item title="备注">{{ item.remark }} </md-detail-item>

          <div class="footer-slot center" slot="footer">
            <img v-for="img in item.pic" :key="img" :src="img | imgSrc" />
          </div>
        </md-bill>
      </md-field>
    </template>

    <md-dialog title="报告名称" :closable="false" v-model="slotDialog.open" :btns="slotDialog.btns">
      <validation-observer ref="observer">
        <md-field style="background-color:#fff">
          <v-input title="报告名称" placeholder="请填写报告名称" v-model="name" clearable rules="required" required isTitleLatent></v-input>
        </md-field>
      </validation-observer>
    </md-dialog>
    <!-- <md-action-bar :actions="[{ text: '提交', onClick: () => (slotDialog.open = true) }]" v-if="canSubmit"></md-action-bar> -->
  </div>
</template>

<script>
import { create, show } from '@/api/answer'

export default {
  data() {
    return {
      answers: [],
      menuId: this.$route.params.menuId,
      menuName: this.$route.params.menuName,
      canSubmit: false,
      name: '',
      slotDialog: {
        open: false,
        btns: [
          {
            text: '提交',
            handler: this.handleSumbit,
          },
        ],
      },
      isMerge: false,
    }
  },
  async created() {
    const { answerId } = this.$route.params
    if (answerId) {
      const res = await show({ id: answerId })
      this.answers = res.data.body
      console.log(this.answers)

      this.canSubmit = false
      this.isMerge = res.data.is_merge
      if (this.isMerge) {
        this.answers = this.answers.map(answer => {
          answer.body = answer.body.filter(item => item.answer !== null)
          return answer
        })
      }
    } else {
      this.canSubmit = true
      this.answers = JSON.parse(this.$ls.get(`answers_${this.menuId}`))
    }
    if (!this.isMerge) {
      this.answers = this.answers.filter(item => item.answer !== null)
    }
  },
  methods: {
    handleClick(val) {
      if (val.has_children) {
        this.$router.push({ name: 'sub-menu', params: { parentId: val.id, parentName: val.name } })
      }
    },
    handleSumbit() {
      this.$refs.observer.validate().then(result => {
        if (!result) {
          return
        }
        const { id } = this.$store.getters['user/info']

        const params = {
          menu_id: this.menuId,
          user_id: id,
          body: this.answers,
          name: this.name,
        }

        create(params).then(() => {
          this.$ls.remove(`answers_${this.menuId}`)

          this.$dialog.alert({
            title: '成功',
            icon: 'success',
            content: '报告提交成功',
            confirmText: '返回首页',
            onConfirm: () => this.$router.push({ name: 'home' }),
          })
        })
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.container.pb, .md-field
  background-color #F3F4F5
  .md-bill
    background-color #FFF
    box-shadow 0 6px 24px rgba(17, 26, 52, 0.05)
    >>>.md-bill-neck
      &:before, &:after
        content ''
        position absolute
        top 0
        width 36px
        height 36px
        border-radius 18px
        background-color #F3F4F5
      &:before
        left -46px
      &:after
        right -46px
    .header-slot
      padding 40px 0 20px 0
      .desc
        margin-top 16px
        color #111A34
        font-size 32px
    .footer-slot
      padding 32px 0
      color #858B9C
      font-size 22px
      line-height 1.5
      border-top solid 1px #E1E4EB
      img
        max-width 100%
        -moz-box-sizing border-box
        box-sizing border-box
      img
        border 1px solid #ddd
        box-shadow 0 0 30px #ccc
        -moz-box-shadow 0 0 30px #ccc
        -webkit-box-shadow 0 0 30px #ccc
        margin-bottom 30px
        margin-top 10px
.pb
  padding-bottom 100px
</style>
